'use strict';

/* libs */
import 'lazysizes';
import svg4everybody from 'svg4everybody';

import bodymovin from 'lottie-web';

/* custom imports */
import NativeConsole from './modules/native-console';

import EzAnims from './modules/animations.js';

/**
 * Chilli App
 * @class
 * @author Thomas Van Kerckvoorde <thomas@chilli.be>
 */

class ChilliApp {

	/**
	 * @constructor
	 */
	constructor() {
		new NativeConsole();

		/* external svg polyfill, https://github.com/jonathantneal/svg4everybody */
		svg4everybody();
	}
}

/**
 * js vanilla equivalent to jquerys $(document).ready -- fires when DOM is ready (assets not yet ready)
 */
window.addEventListener('load', () => {

	anims();

	new EzAnims();

});

window.addEventListener('DOMContentLoaded', function() {
	new ChilliApp();

});

let hamburgerMenu, hamburgerAnim;
let menuClosed = 1;

const anims = () => {
	if (document.querySelector('.hamburger-menu')) {

		if (document.querySelector('.hamburger-menu')) {
			hamburgerAnim = bodymovin.loadAnimation({
				container: document.querySelector('.hamburger-menu'), // the dom element that will contain the animation
				renderer: 'svg',
				loop: false,
				autoplay: false,
				path: '/assets/img/layout/hamburgerAnim.json' // the path to the animation json
			});

			hamburgerAnim.setSpeed(1.5);
		}

		hamburgerMenu = document.querySelector('.hamburger-menu');

		hamburgerMenu.addEventListener('click', () => {
			document.querySelector('.menu-popout').classList.toggle('menu-closed');

			if (menuClosed === 1) {
				hamburgerAnim.setDirection(1);
				hamburgerMenu.classList.add('hamburger-open');
				hamburgerAnim.play();
				menuClosed = 0;
			} else if (menuClosed === 0) {
				hamburgerAnim.setDirection(-1);
				hamburgerMenu.classList.remove('hamburger-open');
				hamburgerAnim.play();
				menuClosed = 1;
			}
		});
	}
};
