'use strict';

/**
 * EzAnims module
 * @class
 * @author Stef De Geest <stef@chilli.be>
 */

export default class EzAnims {
	/**
	 * @constructor
	 */
	constructor() {
		this.initImg();

		window.addEventListener('scroll', () => {
			this.initImg();
		});
	}

	initImg() {
		if (document.querySelector('.anim-top')) {
			let imgstop = document.querySelectorAll('.anim-top');

			imgstop.forEach(img => {
				this.checkIfVisible(img, 'top');
			});
		}

		if (document.querySelector('.anim-right')) {
			let imgstop = document.querySelectorAll('.anim-right');

			imgstop.forEach(img => {
				this.checkIfVisible(img, 'right');
			});
		}

		if (document.querySelector('.anim-left')) {
			let imgstop = document.querySelectorAll('.anim-left');

			imgstop.forEach(img => {
				this.checkIfVisible(img, 'left');
			});
		}
	}

	checkIfVisible(img, dir) {
		let rect = img.getBoundingClientRect();

		let windowHeight = Math.max(window.innerHeight);

		//Als de bovenkant van de afbeelding binnenkomt mag er de klasse op komen.

		if (rect.bottom > 0 && rect.top < windowHeight * 0.8) {
			if (img.classList.contains(`appear-${dir}`)) {
				//
			} else {
				img.classList.add(`appear-${dir}`);
			}
		}
	}
}
